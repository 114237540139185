/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { JobFragmentDoc } from '../../../../shared/api/graphql/fragment/Job.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type CreateCustomJobMutationVariables = Types.Exact<{
  input: Types.CreateJobInput;
}>;

export type CreateCustomJobMutation = {
  __typename?: 'Mutation';
  createJob?: {
    __typename?: 'CreateJobPayload';
    job?: {
      __typename?: 'Job';
      id: string;
      name: Types.Scalars['String']['input'];
      isFree: Types.Scalars['Boolean']['input'];
      partTypesFilteredOut: Types.Scalars['Boolean']['input'];
      priority?: number | null;
      type: Types.JobType;
      partTypes: Array<{
        __typename?: 'JobPartType';
        label?: Types.Scalars['String']['input'] | null;
        required: Types.Scalars['Boolean']['input'];
        partType: {
          __typename?: 'PartType';
          id: string;
          name: Types.Scalars['String']['input'];
          application: Types.PartTypeApplication;
        };
        attributes: Array<{
          __typename?: 'PartTypeAttribute';
          name: Types.Scalars['String']['input'];
          priority: number;
          values?: Array<Types.Scalars['String']['input']> | null;
          required: Types.Scalars['Boolean']['input'];
        }>;
      }>;
    } | null;
  } | null;
};

export const CreateCustomJobDocument = `
    mutation CreateCustomJob($input: CreateJobInput!) {
  createJob(input: $input) {
    job {
      ...Job
    }
  }
}
    ${JobFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateCustomJob: build.mutation<CreateCustomJobMutation, CreateCustomJobMutationVariables>({
      query: (variables) => ({ document: CreateCustomJobDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreateCustomJobMutation } = injectedRtkApi;
