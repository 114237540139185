import { usePrevious } from '@partstech/ui/hooks';
import { isEqual } from '@partstech/ui/utils';
import { useEffect } from 'react';
import { NavigationType, useNavigationType } from 'react-router-dom';
import { useAppLocationsHistory } from '../useAppLocationsHistory';

export const useSaveAppLocationsHistory = () => {
  const navigationType = useNavigationType();

  const { locations, currentLocation, setStoredLocations } = useAppLocationsHistory();

  const previousLocation = usePrevious(currentLocation);

  useEffect(() => {
    if (isEqual(currentLocation, previousLocation)) {
      return;
    }

    if (navigationType === NavigationType.Push) {
      const previousLocationIndex = locations.findIndex((item) => item.key === previousLocation?.key);

      if (previousLocationIndex !== -1) {
        setStoredLocations([...locations.slice(0, previousLocationIndex + 1), currentLocation]);
        return;
      }

      setStoredLocations([...locations, currentLocation]);
      return;
    }

    if (navigationType === NavigationType.Replace) {
      setStoredLocations([...locations.slice(0, locations.length - 1), currentLocation]);
      return;
    }

    const currentLocationIndex = locations.findIndex((item) => item.key === currentLocation.key);

    if (navigationType === NavigationType.Pop && currentLocationIndex === -1) {
      setStoredLocations([...locations, currentLocation]);
    }
  }, [currentLocation, navigationType, previousLocation, setStoredLocations, locations]);
};
