/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import type * as Types from '../graphql';

export type JobFragment = {
  __typename?: 'Job';
  id: string;
  name: Types.Scalars['String']['input'];
  isFree: Types.Scalars['Boolean']['input'];
  partTypesFilteredOut: Types.Scalars['Boolean']['input'];
  priority?: number | null;
  type: Types.JobType;
  partTypes: Array<{
    __typename?: 'JobPartType';
    label?: Types.Scalars['String']['input'] | null;
    required: Types.Scalars['Boolean']['input'];
    partType: {
      __typename?: 'PartType';
      id: string;
      name: Types.Scalars['String']['input'];
      application: Types.PartTypeApplication;
    };
    attributes: Array<{
      __typename?: 'PartTypeAttribute';
      name: Types.Scalars['String']['input'];
      priority: number;
      values?: Array<Types.Scalars['String']['input']> | null;
      required: Types.Scalars['Boolean']['input'];
    }>;
  }>;
};

export const JobFragmentDoc = `
    fragment Job on Job {
  id
  name
  isFree
  partTypes {
    partType {
      id
      name
      application
    }
    label
    required
    attributes {
      name
      priority
      values
      required
    }
  }
  partTypesFilteredOut
  priority
  type
}
    `;
