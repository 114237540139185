import { useMemo } from 'react';
import { createJobsFromQuery } from 'factories';
import { CustomJobsSortBy, type Job, SortOrder } from 'shared/api';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import { api as generatedApi } from './GetCustomJobs.generated';

const emptyCustomJobs: Job[] = [];

const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['CustomJob'],
  endpoints: {
    GetCustomJobs: {
      providesTags: ['CustomJob'],
    },
  },
});

const { useGetCustomJobsQuery } = api;

type Args = {
  skip?: boolean;
  sortOrder?: SortOrder;
};

export const useGetCustomJobs = ({ skip = false, sortOrder = SortOrder.Desc }: Args = {}) => {
  const { hasCurrentUser } = useGetCurrentUser();

  const { data, isLoading } = useGetCustomJobsQuery(
    { sortBy: CustomJobsSortBy.Priority, sortOrder },
    {
      skip: skip || !hasCurrentUser,
    }
  );

  const jobs = useMemo(() => data?.currentUser?.activeMember?.shop?.customJobs ?? emptyCustomJobs, [data]);
  const customJobs = useMemo(() => createJobsFromQuery(jobs), [jobs]);

  return {
    customJobs,
    isLoading,
  };
};
