import { useMemo } from 'react';
import { MaxPreferredBrandsCount } from 'constant';
import { PreferredBrandsMode } from 'shared/api';
import { usePreferredBrandsMode } from 'store/queries/currentUser/usePreferredBrandsMode';
import { getPreferredBrandsByProducts } from 'utils';
import { usePreferredBrands } from './usePreferredBrands';
import type { Product } from 'models';
import type { BrandHookType, BrandOption } from 'types/preferredBrand';

type Props = {
  products: Product[];
  type: BrandHookType;
  isPartNumberSearch?: boolean;
};

const getProductBrandOptions = (products: Product[]) =>
  products.reduce<BrandOption[]>((brandOption, { brand, partType }) => {
    if (!brand) {
      brandOption.push({
        value: 'Not Specified',
        name: 'Not Specified',
        partTypeId: null,
      });
    }

    if (brand?.id && brand.name && !brandOption.find((item) => item.name === brand.name)) {
      brandOption.push({
        value: brand.id,
        name: brand.name,
        partTypeId: partType?.id ?? null,
      });
    }

    return brandOption;
  }, []);

const sortBrandOptions = (options: BrandOption[]) =>
  options.sort((a, b) => {
    if (a.priority !== undefined && b.priority !== undefined) {
      return a.priority < b.priority ? -1 : 1;
    }

    return a.name.toLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1;
  });

export const useBrandOptions = ({ products, type, isPartNumberSearch }: Props) => {
  const { tiresPreferredBrandsMode, partsPreferredBrandsMode } = usePreferredBrandsMode();

  const mode = type === 'Part' ? partsPreferredBrandsMode : tiresPreferredBrandsMode;

  const { preferredBrands } = usePreferredBrands({ mode, type });

  const maxPreferredBrandsCount = useMemo(() => {
    if (mode === PreferredBrandsMode.Custom) {
      return preferredBrands.length;
    }

    return type === 'Part' ? MaxPreferredBrandsCount.Parts : MaxPreferredBrandsCount.Tires;
  }, [mode, preferredBrands.length, type]);

  const productBrandOptions = useMemo(() => getProductBrandOptions(products), [products]);
  const limitedPreferredBrands = useMemo(
    () => getPreferredBrandsByProducts(preferredBrands, products, maxPreferredBrandsCount),
    [maxPreferredBrandsCount, preferredBrands, products]
  );

  return useMemo(() => {
    const preferredBrandOptions: BrandOption[] = [];
    const regularBrandOptions: BrandOption[] = [];

    if (mode === PreferredBrandsMode.Disable || isPartNumberSearch) {
      return {
        preferredBrandOptions: [],
        regularBrandOptions: sortBrandOptions(productBrandOptions),
        maxPreferredBrandsCount,
      };
    }

    productBrandOptions.forEach((option) => {
      const preferredBrand = limitedPreferredBrands.find(
        (brand) => brand.id === option.value || brand.matchingBrandsIds?.includes(String(option.value))
      );

      preferredBrand
        ? preferredBrandOptions.push({
            ...option,
            priority: mode === PreferredBrandsMode.Custom ? preferredBrand.priority : undefined,
          })
        : regularBrandOptions.push(option);
    });

    return {
      preferredBrandOptions: sortBrandOptions(preferredBrandOptions),
      regularBrandOptions: sortBrandOptions(regularBrandOptions),
      maxPreferredBrandsCount,
    };
  }, [mode, isPartNumberSearch, productBrandOptions, limitedPreferredBrands, maxPreferredBrandsCount]);
};
