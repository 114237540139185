import { useToggle } from '@partstech/ui/hooks';
import { useMemo } from 'react';
import { useAppLocationsHistory } from 'app/AppRouter/useAppLocationsHistory';
import { useSmsConnectionsRouter } from 'hooks/smsConnections';
import { SmsConnectionsPages } from 'utils/url';

export const useBackButton = () => {
  const {
    pathParams: { page: smsConnectionsPage },
  } = useSmsConnectionsRouter();

  const { previousLocation } = useAppLocationsHistory();

  const isShowBackButton = useMemo(() => {
    if (!previousLocation?.pathname) {
      return false;
    }

    return [SmsConnectionsPages.Success, SmsConnectionsPages.Error, SmsConnectionsPages.Connect].every(
      (page) => page !== smsConnectionsPage
    );
  }, [previousLocation?.pathname, smsConnectionsPage]);

  const { isToggle: hasBackButton, turnOn: showBackButton, turnOff: hideBackButton } = useToggle(isShowBackButton);

  return { hasBackButton, hideBackButton, showBackButton };
};
