/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { JobFragmentDoc } from '../../../../shared/api/graphql/fragment/Job.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetCustomJobsQueryVariables = Types.Exact<{
  sortBy?: Types.InputMaybe<Types.CustomJobsSortBy>;
  sortOrder?: Types.InputMaybe<Types.SortOrder>;
}>;

export type GetCustomJobsQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        customJobs?: Array<{
          __typename?: 'Job';
          id: string;
          name: Types.Scalars['String']['input'];
          isFree: Types.Scalars['Boolean']['input'];
          partTypesFilteredOut: Types.Scalars['Boolean']['input'];
          priority?: number | null;
          type: Types.JobType;
          partTypes: Array<{
            __typename?: 'JobPartType';
            label?: Types.Scalars['String']['input'] | null;
            required: Types.Scalars['Boolean']['input'];
            partType: {
              __typename?: 'PartType';
              id: string;
              name: Types.Scalars['String']['input'];
              application: Types.PartTypeApplication;
            };
            attributes: Array<{
              __typename?: 'PartTypeAttribute';
              name: Types.Scalars['String']['input'];
              priority: number;
              values?: Array<Types.Scalars['String']['input']> | null;
              required: Types.Scalars['Boolean']['input'];
            }>;
          }>;
        }> | null;
      } | null;
    } | null;
  } | null;
};

export const GetCustomJobsDocument = `
    query GetCustomJobs($sortBy: CustomJobsSortBy, $sortOrder: SortOrder) {
  currentUser {
    activeMember {
      shop {
        customJobs(sortBy: $sortBy, sortOrder: $sortOrder) {
          ...Job
        }
      }
    }
  }
}
    ${JobFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetCustomJobs: build.query<GetCustomJobsQuery, GetCustomJobsQueryVariables | void>({
      query: (variables) => ({ document: GetCustomJobsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetCustomJobsQuery, useLazyGetCustomJobsQuery } = injectedRtkApi;
