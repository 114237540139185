import type { PricePackageLine } from './PricePackageLine';

type PricePackageData = {
  id: string;
  name: string;
  isDefault: boolean;
  items: PricePackageLine[];
};

export class PricePackage {
  id: string;

  name: string;

  isDefault: boolean;

  items: PricePackageLine[];

  constructor(data: PricePackageData) {
    this.id = data.id;
    this.name = data.name;
    this.isDefault = data.isDefault;
    this.items = data.items;
  }
}
