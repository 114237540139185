import { PricePackage } from '../model/PricePackage';
import { PricePackageLine } from '../model/PricePackageLine';
import type { GetPricePackagesQuery } from '../api/getPricePackages/GetPricePackages.generated';

type CurrentUserData = NonNullable<GetPricePackagesQuery['currentUser']>;
type ActiveMemberData = NonNullable<CurrentUserData['activeMember']>;
type ShopData = NonNullable<ActiveMemberData['shop']>;
type RetailPricingData = NonNullable<ShopData['retailPricing']>;
type PricePackageData = NonNullable<RetailPricingData['packages']>[number];
type PricePackageItemData = PricePackageData['items'][number];

const getCalculatedBy = (item: PricePackageItemData) => {
  if ('feeCalculatedBy' in item) {
    return item.feeCalculatedBy;
  }
  if ('addonCalculatedBy' in item) {
    return item.addonCalculatedBy;
  }
  if ('discountCalculatedBy' in item) {
    return item.discountCalculatedBy;
  }
  return [];
};

export const createPricePackageFromQuery = (data: PricePackageData) =>
  new PricePackage({
    id: data.id,
    name: data.name,
    isDefault: data.isDefault,
    items: data.items.map(
      (item) =>
        new PricePackageLine({
          type: item.type,
          name: item.name,
          amount: item.price,
          description: item.description,
          createdAt: item.createdAt,
          isPerTire: 'isPerTire' in item ? item.isPerTire : false,
          isApplyTax: 'isApplyTax' in item ? item.isApplyTax : false,
          isPercentage: 'isPercentage' in item ? item.isPercentage : false,
          isPerQuantity: 'percentageAmount' in item ? item.percentageAmount?.isPerQuantity : false,
          max: 'percentageAmount' in item ? item.percentageAmount?.max : null,
          min: 'percentageAmount' in item ? item.percentageAmount?.min : null,
          calculatedBy: getCalculatedBy(item),
        })
    ),
  });
