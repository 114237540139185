import { css } from '@emotion/react';
import { Box, Icon, IconButton, MenuItem, Tag } from '@partstech/ui';
import React, { useCallback } from 'react';
import { Highlight } from 'shared/ui';
import type { IconName } from '@partstech/ui';
import type { PartType } from 'models';

export type OptionProps<T> = {
  checkedIcon?: (option: T) => IconName;
  isSelected: boolean;
  option: T;
  onSelect?: (option: T) => void;
  onOpen?: (option: T) => void;
  secondaryText?: string;
  highlightedText?: string;
  disabledText?: string;
  tag?: string;
  hasPrefix?: boolean;
  id?: string;
};

type OptionItem = {
  categoryId?: string;
  partTypes?: PartType[];
  id: string;
  isDisabled?: boolean;
  name: string;
};

const styles = {
  menuItem: (isFullSize: boolean) => css`
    .list-item-content {
      flex: ${isFullSize ? '1 1' : '1 1 100%'};
    }

    .list-item-adornment-after {
      flex: 1 1;
      justify-content: flex-end;
    }
  `,
};

export const Option = <T extends OptionItem>({
  isSelected,
  option,
  onSelect,
  onOpen,
  secondaryText,
  highlightedText = '',
  checkedIcon = () => 'check_box',
  disabledText,
  id,
  hasPrefix,
  tag = '',
}: OptionProps<T>) => {
  const handleCheckboxClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();

      onSelect && onSelect(option);
    },
    [onSelect, option]
  );

  const handleOpenClick = useCallback(() => {
    if (onOpen) {
      onOpen(option);
    }
  }, [onOpen, option]);

  return (
    <MenuItem
      css={styles.menuItem(Boolean(tag))}
      data-testid="listItem"
      id={id}
      tooltip={option.isDisabled ? disabledText : ''}
      prefix={
        hasPrefix && (
          <IconButton variant="text" disabled={option.isDisabled} onClick={handleCheckboxClick}>
            <Icon name={isSelected ? checkedIcon(option) : 'check_box_blank'} />
          </IconButton>
        )
      }
      suffix={
        <Box display="flex" alignItems="center" justifyContent="space-between" gap={2}>
          {tag && <Tag>{tag}</Tag>}
          {onOpen ? <Icon name="chevron_right" /> : null}
        </Box>
      }
      contentAlignment="center"
      secondaryText={secondaryText}
      onClick={onOpen ? handleOpenClick : handleCheckboxClick}
      disabled={option.isDisabled}
    >
      <Highlight value={highlightedText}>{option.name}</Highlight>
    </MenuItem>
  );
};
