import { useSessionStorage } from '@partstech/ui/hooks';
import { useCallback, useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { AppLocation, useAppLocation, type AppLocationBase } from 'app/AppRouter/useAppLocation';
import { SessionStorageKeys } from 'constant';
import type { Routes } from 'constant';

/**
 * Helper function that finds the index of a location in the locations array
 * @param {AppLocation[]} locations - Array of location objects
 * @param {AppLocation} locationElement - Location object to find
 * @returns {number} Index of the location or -1 if not found
 * @private
 */
const findLocationIndex = (locations: AppLocationBase[], locationElement: AppLocationBase) =>
  locations.findIndex((item) => item.key === locationElement.key && item.pathname === locationElement.pathname);

/**
 * Helper function that calculates the distance (number of steps) between two locations in a location array
 * @param targetLocation - The target location to measure distance to
 * @param locations - Array of location objects to search within
 * @param currentLocation - Current location to measure from
 * @returns The number of steps between current and target locations:
 *          - Positive number: Forward navigation (future location)
 *          - Negative number: Backward navigation (past location)
 *          - 0: Same location
 *          - null: Location not found in history
 * @private
 */
const calculateLocationDistance = (
  targetLocation: AppLocation,
  locations: AppLocationBase[],
  currentLocation: AppLocation
): number | null => {
  const currentIndex = findLocationIndex(locations, currentLocation);
  const targetIndex = findLocationIndex(locations, targetLocation);

  if (targetIndex === -1 || currentIndex === -1) {
    return null;
  }

  return targetIndex - currentIndex;
};

const emptyLocations: AppLocation[] = [];

type Args = Partial<{
  excludeSearchChanges: boolean;
  excludePathChangesPatterns: (Routes | string)[];
  excludeHashChanges: boolean;
}>;

/**
 * Hook for managing application location history
 *
 * This hook keeps track of navigation history by storing location objects in session storage.
 * It provides access to the current, previous, and next locations in the history.
 *
 * The hook is fully synchronized with browser's native navigation controls (back/forward buttons)
 * and automatically tracks their usage. This means any navigation performed through browser controls
 * will be properly reflected in the location history maintained by this hook.
 *
 * @param {Object} options - Configuration options
 * @param {boolean} [options.excludeSearchChanges=false] - When true, excludes duplicate pathnames to avoid tracking just search parameter changes
 * @param {Array<Routes|string>} [options.excludePathChangesPatterns=[]] - List of path patterns to exclude from history tracking
 * @param {boolean} [options.excludeHashChanges=false] - When true, excludes duplicate pathnames to avoid tracking just hash changes
 *
 * @returns {Object} History utilities
 * @returns {AppLocation[]} returns.locations - Array of all tracked locations
 * @returns {AppLocation} returns.currentLocation - Current location object
 * @returns {AppLocation|null} returns.nextLocation - Next location in history (if any)
 * @returns {AppLocation|null} returns.previousLocation - Previous location in history (if any)
 * @returns {Function} returns.setStoredLocations - Function to update stored locations
 * @returns {Function} returns.getLocationDistance - Function to calculate steps to a location
 *
 * @example
 * // Basic usage
 * const { currentLocation, previousLocation } = useAppLocationsHistory();
 *
 * @example
 * // Excluding search parameter changes
 * const { locations } = useAppLocationsHistory({
 *   excludeSearchChanges: true
 * });
 *
 * @example
 * // Excluding specific paths from history
 * const { locations } = useAppLocationsHistory({
 *   excludePathChangesPatterns: [Routes.HOME, '/products/*']
 * });
 *
 * @example
 * // Using with browser navigation
 * const { previousLocation } = useAppLocationsHistory();
 * // When user clicks browser back button, previousLocation will be updated automatically
 * // and can be used to determine the previous page they were on
 */
export const useAppLocationsHistory = ({
  excludeSearchChanges,
  excludePathChangesPatterns,
  excludeHashChanges,
}: Args = {}) => {
  const location = useAppLocation();

  const [storedLocations = emptyLocations, setStoredLocations] = useSessionStorage<AppLocationBase[]>(
    SessionStorageKeys.LOCATION_HISTORY
  );

  const locations = useMemo(() => {
    const filteredStoredLocations: AppLocation[] = [];

    const uniqueStoredLocationsSearch = new Set<string>();
    const uniqueStoredLocationsHash = new Set<string>();

    for (let indexElement = storedLocations.length - 1; indexElement >= 0; indexElement -= 1) {
      const storedLocation = storedLocations[indexElement]!;

      const isExcludedPath =
        excludePathChangesPatterns?.some(
          (pattern) => storedLocation.pathname === pattern || matchPath(pattern, storedLocation.pathname) !== null
        ) ?? false;

      const isDuplicatedPath = excludeSearchChanges && uniqueStoredLocationsSearch.has(storedLocation.pathname);
      const isDuplicatedHash = excludeHashChanges && uniqueStoredLocationsHash.has(storedLocation.pathname);

      if (!isExcludedPath && !isDuplicatedPath && !isDuplicatedHash) {
        uniqueStoredLocationsSearch.add(storedLocation.pathname);
        uniqueStoredLocationsHash.add(storedLocation.pathname);

        filteredStoredLocations.push(new AppLocation(storedLocation));
      }
    }

    return filteredStoredLocations.reverse();
  }, [excludeHashChanges, excludePathChangesPatterns, excludeSearchChanges, storedLocations]);

  const currentLocationIndex = useMemo(() => findLocationIndex(locations, location), [location, locations]);

  const getLocationDistance = useCallback(
    (targetLocation: AppLocation | null): number | null => {
      if (!targetLocation) {
        return null;
      }

      return calculateLocationDistance(targetLocation, storedLocations, location);
    },
    [location, storedLocations]
  );

  return useMemo(() => {
    let nextLocation: AppLocation | null = null;
    let previousLocation: AppLocation | null = locations[locations.length - 1] ?? null;

    if (currentLocationIndex !== -1) {
      nextLocation = locations[currentLocationIndex + 1] ?? null;
      previousLocation = locations[currentLocationIndex - 1] ?? null;
    }

    return {
      locations,
      currentLocation: location,
      nextLocation,
      previousLocation,
      setStoredLocations,
      getLocationDistance,
    } as const;
  }, [currentLocationIndex, location, setStoredLocations, locations, getLocationDistance]);
};
