import type { ProductSortValues } from './product';
import type { AttributeName } from 'constant';
import type { Brand, Product } from 'models';

export type DynamicAttributeName = string;
export type DynamicAttributeValues = string[];
export type DynamicAttributeRanges = string[][];

export type DynamicAttributes = Record<DynamicAttributeName, DynamicAttributeValues>;

export type Filter = {
  options: FilterOption[];
  isCollapsed: boolean;
  isHidden?: boolean;
  label: string;
  name: AttributeName | 'availability' | 'manufacturers' | 'parts' | 'HasRebate' | DynamicAttributeFilterName;
  sortIndex: number;
  orderBy?:
    | ((a: FilterOption, b: FilterOption) => number)
    | keyof FilterOption
    | { field: keyof FilterOption; direction: 'asc' | 'desc' };
  /**
   * Indicates a mixed selection filter that can contain both radio buttons and checkboxes.
   */
  mixedSelection?: boolean;
};

export type DynamicAttributeFilterName = `attributes.${string}`;

export type CheckedFilters = Record<Filter['name'] | DynamicAttributeFilterName, string[]>;

export enum SearchTypes {
  PARTS = 'parts',
  TIRES = 'tires',
}

export type SearchFormValues = {
  type: SearchTypes | null;
  vehicleId: string | null;
  state: string | null;
  plate: string | null;
  vin: string | null;
  partText: string | null;
  partTextId: string | null;
  partNumber: string | null;
  manufacturers: string[] | null;
  tireId: string | number | null;
  tiresSize: string | null;
  partTypes: number[] | null;
};

export type KeywordSearchFormValues = {
  type: SearchTypes.PARTS;
  vehicleId: string | null;
  state: string | null;
  plate: string | null;
  vin: string | null;
  partText: string | null;
  partTextId: string | null;
};

export type NonAppSearchFormValues = {
  type: SearchTypes.PARTS;
  vehicleId: string | null;
  state: string | null;
  plate: string | null;
  vin: string | null;
  partText: string | null;
  partTextId: string | null;
  partTypes: number[] | null;
  attributes: { [k: string]: string[] };
};

export type TiresSearchFormValues = {
  type: SearchTypes.TIRES;
  vehicleId: string | null;
  state: string | null;
  plate: string | null;
  vin: string | null;
  tireId: string | number | null;
  tiresSize: string | null;
  partNumber: string | null;
};

export type PartNumberFormValues = {
  type: SearchTypes.PARTS;
  vehicleId: string | null;
  state: string | null;
  plate: string | null;
  vin: string | null;
  partNumber: string | null;
  manufacturers: string[] | null;
};

export type SearchValues =
  | TiresSearchFormValues
  | NonAppSearchFormValues
  | KeywordSearchFormValues
  | PartNumberFormValues;

export type SuppliersFiltersMap = Record<number, { isTiresDisconnected: boolean; supplierId: string }>;

export type FilterOptionType = 'checkbox' | 'radio';

export type FilterOption = {
  value: string;
  name: string;
  label?: string;
  count?: number;
  checked?: boolean;
  disabled?: boolean;
  type?: FilterOptionType;
  tooltip?: boolean;
};

export enum Availability {
  IN_STOCK = '1',
  OUT_OF_STOCK = '2',
  SPECIAL_ORDER = '3',
  BACK_ORDER = '4',
}

export enum AvailabilityType {
  FastestDelivery = 'Fastest Delivery',
  DirectDelivery = 'Direct Delivery',
  InNetwork = 'In Network',
}

export type CreateFiltersConfig = {
  attributes: DynamicAttributes | undefined;
  matchingSets?: MatchingSets;
  shouldUseAvailability: boolean;
  shouldUseMatchingSets: boolean;
  shouldUseGroupAvailability?: boolean;
  supplierId: string;
};

/**
 * @deprecated Use features/searchForm/types.ts:SearchQuery instead
 */
export type SearchParams = Partial<
  {
    attributes: Record<DynamicAttributeName, string[]>;
    vehicle: string;
    year: string;
    make: string;
    model: string;
    submodel: string;
    engine: string;
    part_text: string;
    part_text_id: string;
    part_types: string;
    partnumber: string;
    tire_id: string;
    tire_sizes: string;
    vin: string;
    state: string;
    plate: string;
    selected_distributor: string;
    type: string;
    debug: string;
    page: number;
    dealers: number;
    step: string;
    task_type: SearchSequenceType;
    sort: ProductSortValues;
    job_id: string;
    prefill: Prefill;
  } & CheckedFilters
>;

export type SearchUrlBySearchValues = {
  searchValues: Partial<SearchFormValues> & Partial<{ attributes: Record<string, string[]>; parts: string[] }>;
  selectedSupplier?: string | null;
  dealers?: boolean | null;
  fitOnly?: boolean | null;
  step?: number | null;
  taskType?: SearchSequenceType;
  sort?: ProductSortValues;
  jobId?: string;
  availability?: string[];
  prefill?: Prefill;
};

export type MatchingSetId = `${Brand['id']}:${Product['title']}`;
export type MatchingSets = Record<MatchingSetId, Product[]>;

export type SearchTask = {
  searchParams: SearchParams;
  title: string;
  subtitle?: string;
  isCompleted: boolean;
  optional?: boolean;
};

export enum SearchSequenceType {
  AlternateParts = 'alt-parts',
  Diagram = 'diagram',
  Job = 'job',
  CustomJob = 'custom-job',
  LaborPartTypes = 'labor-part-types',
  LegacyJob = 'legacy-job',
  PartTypes = 'part-types',
  PunchoutPartTypes = 'punchout-part-types',
}

export type SearchSequence = {
  tasks: SearchTask[];
  title: string;
  id: string;
  categoryId?: string;
  incompatiblePartsHidden?: boolean;
  type: SearchSequenceType;
};

export type ReportFormData = {
  image: File | null;
  brand: string;
  partNumber: string;
  notes: string;
};

export type Prefill = 'required' | 'skip' | 'none';
