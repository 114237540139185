import { Box, Card } from '@partstech/ui';
import { ConfigureRetailPrice } from '../ConfigureRetailPrice';
import { DiagramQuickLink } from '../DiagramQuickLink';
import { useSearchResults } from '../SearchResultsContext';
import { RelatedJobs } from './RelatedJobs';
import type { CardProps } from '@partstech/ui';
import type { Vehicle } from 'entities/vehicle';

type Props = CardProps & {
  showDiagrams: boolean;
  showRetailPricing: boolean;
  onDismiss?: () => void;
  vehicle: Vehicle | null;
};

export const RelatedFeaturesBlock = ({ showDiagrams, showRetailPricing, onDismiss, vehicle, ...props }: Props) => {
  const { isFiltersImprovementEnabled } = useSearchResults();

  if (!isFiltersImprovementEnabled) {
    return (
      <Box>
        {showRetailPricing && <ConfigureRetailPrice onDismiss={onDismiss} noWrap />}

        {showDiagrams && (
          <DiagramQuickLink isFiltersImprovementEnabled={isFiltersImprovementEnabled} onDismiss={onDismiss} />
        )}
      </Box>
    );
  }

  return (
    <Card elevation={null} p={{ sm: 0, md: 4 }} display="flex" flexDirection="column" gap={2} {...props}>
      {showDiagrams && (
        <DiagramQuickLink isFiltersImprovementEnabled={isFiltersImprovementEnabled} onDismiss={onDismiss} />
      )}

      <RelatedJobs vehicle={vehicle} onDismiss={onDismiss} />

      {showRetailPricing && <ConfigureRetailPrice onDismiss={onDismiss} noWrap mt={2} />}
    </Card>
  );
};
