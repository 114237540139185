import { Box, Typography } from '@partstech/ui';
import type { BoxProps } from '@partstech/ui';
import type { FilterProps } from 'pages/SearchResults/Filters/Filter/DefaultFilter/types';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<Pick<FilterProps, 'name' | 'isCollapsed' | 'label'> & BoxProps>;

export const DefaultFilterWrapper = ({ children, name, isCollapsed, label, ...props }: Props) => (
  <Box {...props}>
    <Box pb={2}>
      <Typography variant="subtitle1">{label}</Typography>
    </Box>

    {children}
  </Box>
);
