/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { PricePackageFragmentDoc } from '../pricePackage/PricePackage.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetPricePackagesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetPricePackagesQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        retailPricing?: {
          __typename?: 'RetailPricing';
          packages?: Array<{
            __typename?: 'Package';
            id: string;
            name: Types.Scalars['String']['input'];
            isDefault: Types.Scalars['Boolean']['input'];
            items: Array<
              | {
                  __typename?: 'Addon';
                  name: Types.Scalars['String']['input'];
                  createdAt: Types.Scalars['DateTime']['input'];
                  price: Types.Scalars['BigDecimal']['input'];
                  description?: Types.Scalars['String']['input'] | null;
                  isPerTire: Types.Scalars['Boolean']['input'];
                  isApplyTax: Types.Scalars['Boolean']['input'];
                  isPercentage: Types.Scalars['Boolean']['input'];
                  addonCalculatedBy: Array<Types.AddonCalculatedBy>;
                  type: 'Addon';
                  percentageAmount?: {
                    __typename?: 'PercentageAmount';
                    max?: Types.Scalars['BigDecimal']['input'] | null;
                    min?: Types.Scalars['BigDecimal']['input'] | null;
                    isPerQuantity: Types.Scalars['Boolean']['input'];
                  } | null;
                }
              | {
                  __typename?: 'Discount';
                  name: Types.Scalars['String']['input'];
                  createdAt: Types.Scalars['DateTime']['input'];
                  price: Types.Scalars['BigDecimal']['input'];
                  description?: Types.Scalars['String']['input'] | null;
                  isPerTire: Types.Scalars['Boolean']['input'];
                  isPercentage: Types.Scalars['Boolean']['input'];
                  discountCalculatedBy: Array<Types.DiscountCalculatedBy>;
                  type: 'Discount';
                  percentageAmount?: {
                    __typename?: 'PercentageAmount';
                    max?: Types.Scalars['BigDecimal']['input'] | null;
                    min?: Types.Scalars['BigDecimal']['input'] | null;
                    isPerQuantity: Types.Scalars['Boolean']['input'];
                  } | null;
                }
              | {
                  __typename?: 'Disposal';
                  name: Types.Scalars['String']['input'];
                  createdAt: Types.Scalars['DateTime']['input'];
                  price: Types.Scalars['BigDecimal']['input'];
                  description?: Types.Scalars['String']['input'] | null;
                  isApplyTax: Types.Scalars['Boolean']['input'];
                  isPercentage: Types.Scalars['Boolean']['input'];
                  type: 'Disposal';
                  percentageAmount?: {
                    __typename?: 'PercentageAmount';
                    max?: Types.Scalars['BigDecimal']['input'] | null;
                    min?: Types.Scalars['BigDecimal']['input'] | null;
                    isPerQuantity: Types.Scalars['Boolean']['input'];
                  } | null;
                }
              | {
                  __typename?: 'Fee';
                  name: Types.Scalars['String']['input'];
                  createdAt: Types.Scalars['DateTime']['input'];
                  price: Types.Scalars['BigDecimal']['input'];
                  description?: Types.Scalars['String']['input'] | null;
                  isPerTire: Types.Scalars['Boolean']['input'];
                  isApplyTax: Types.Scalars['Boolean']['input'];
                  feeCalculatedBy: Array<Types.FeeCalculatedBy>;
                  isPercentage: Types.Scalars['Boolean']['input'];
                  type: 'Fee';
                  percentageAmount?: {
                    __typename?: 'PercentageAmount';
                    max?: Types.Scalars['BigDecimal']['input'] | null;
                    min?: Types.Scalars['BigDecimal']['input'] | null;
                    isPerQuantity: Types.Scalars['Boolean']['input'];
                  } | null;
                }
              | {
                  __typename?: 'Labor';
                  name: Types.Scalars['String']['input'];
                  createdAt: Types.Scalars['DateTime']['input'];
                  price: Types.Scalars['BigDecimal']['input'];
                  description?: Types.Scalars['String']['input'] | null;
                  isPerTire: Types.Scalars['Boolean']['input'];
                  isApplyTax: Types.Scalars['Boolean']['input'];
                  type: 'Labor';
                }
            >;
          }> | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export const GetPricePackagesDocument = `
    query GetPricePackages {
  currentUser {
    activeMember {
      shop {
        retailPricing {
          packages {
            ...PricePackage
          }
        }
      }
    }
  }
}
    ${PricePackageFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetPricePackages: build.query<GetPricePackagesQuery, GetPricePackagesQueryVariables | void>({
      query: (variables) => ({ document: GetPricePackagesDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetPricePackagesQuery, useLazyGetPricePackagesQuery } = injectedRtkApi;
