/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { PercentageAmountFragmentDoc } from '../../../../shared/api/graphql/fragment/PercentageAmount.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type PricePackageFragment = {
  __typename?: 'Package';
  id: string;
  name: Types.Scalars['String']['input'];
  isDefault: Types.Scalars['Boolean']['input'];
  items: Array<
    | {
        __typename?: 'Addon';
        name: Types.Scalars['String']['input'];
        createdAt: Types.Scalars['DateTime']['input'];
        price: Types.Scalars['BigDecimal']['input'];
        description?: Types.Scalars['String']['input'] | null;
        isPerTire: Types.Scalars['Boolean']['input'];
        isApplyTax: Types.Scalars['Boolean']['input'];
        isPercentage: Types.Scalars['Boolean']['input'];
        addonCalculatedBy: Array<Types.AddonCalculatedBy>;
        type: 'Addon';
        percentageAmount?: {
          __typename?: 'PercentageAmount';
          max?: Types.Scalars['BigDecimal']['input'] | null;
          min?: Types.Scalars['BigDecimal']['input'] | null;
          isPerQuantity: Types.Scalars['Boolean']['input'];
        } | null;
      }
    | {
        __typename?: 'Discount';
        name: Types.Scalars['String']['input'];
        createdAt: Types.Scalars['DateTime']['input'];
        price: Types.Scalars['BigDecimal']['input'];
        description?: Types.Scalars['String']['input'] | null;
        isPerTire: Types.Scalars['Boolean']['input'];
        isPercentage: Types.Scalars['Boolean']['input'];
        discountCalculatedBy: Array<Types.DiscountCalculatedBy>;
        type: 'Discount';
        percentageAmount?: {
          __typename?: 'PercentageAmount';
          max?: Types.Scalars['BigDecimal']['input'] | null;
          min?: Types.Scalars['BigDecimal']['input'] | null;
          isPerQuantity: Types.Scalars['Boolean']['input'];
        } | null;
      }
    | {
        __typename?: 'Disposal';
        name: Types.Scalars['String']['input'];
        createdAt: Types.Scalars['DateTime']['input'];
        price: Types.Scalars['BigDecimal']['input'];
        description?: Types.Scalars['String']['input'] | null;
        isApplyTax: Types.Scalars['Boolean']['input'];
        isPercentage: Types.Scalars['Boolean']['input'];
        type: 'Disposal';
        percentageAmount?: {
          __typename?: 'PercentageAmount';
          max?: Types.Scalars['BigDecimal']['input'] | null;
          min?: Types.Scalars['BigDecimal']['input'] | null;
          isPerQuantity: Types.Scalars['Boolean']['input'];
        } | null;
      }
    | {
        __typename?: 'Fee';
        name: Types.Scalars['String']['input'];
        createdAt: Types.Scalars['DateTime']['input'];
        price: Types.Scalars['BigDecimal']['input'];
        description?: Types.Scalars['String']['input'] | null;
        isPerTire: Types.Scalars['Boolean']['input'];
        isApplyTax: Types.Scalars['Boolean']['input'];
        feeCalculatedBy: Array<Types.FeeCalculatedBy>;
        isPercentage: Types.Scalars['Boolean']['input'];
        type: 'Fee';
        percentageAmount?: {
          __typename?: 'PercentageAmount';
          max?: Types.Scalars['BigDecimal']['input'] | null;
          min?: Types.Scalars['BigDecimal']['input'] | null;
          isPerQuantity: Types.Scalars['Boolean']['input'];
        } | null;
      }
    | {
        __typename?: 'Labor';
        name: Types.Scalars['String']['input'];
        createdAt: Types.Scalars['DateTime']['input'];
        price: Types.Scalars['BigDecimal']['input'];
        description?: Types.Scalars['String']['input'] | null;
        isPerTire: Types.Scalars['Boolean']['input'];
        isApplyTax: Types.Scalars['Boolean']['input'];
        type: 'Labor';
      }
  >;
};

export const PricePackageFragmentDoc = `
    fragment PricePackage on Package {
  id
  name
  isDefault: default
  items {
    type: __typename
    ... on Labor {
      name
      createdAt
      isPerTire: perTire
      isApplyTax: applyTax
      price
      description
    }
    ... on Fee {
      name
      createdAt
      price
      isPerTire: perTire
      isApplyTax: applyTax
      feeCalculatedBy: calculatedBy
      isPercentage: percentage
      percentageAmount {
        ...PercentageAmount
      }
      description
    }
    ... on Disposal {
      name
      createdAt
      price
      isApplyTax: applyTax
      isPercentage: percentage
      percentageAmount {
        ...PercentageAmount
      }
      description
    }
    ... on Addon {
      name
      createdAt
      price
      isPerTire: perTire
      isApplyTax: applyTax
      isPercentage: percentage
      percentageAmount {
        ...PercentageAmount
      }
      addonCalculatedBy: calculatedBy
      description
    }
    ... on Discount {
      name
      createdAt
      price
      isPerTire: perTire
      isPercentage: percentage
      percentageAmount {
        ...PercentageAmount
      }
      discountCalculatedBy: calculatedBy
      description
    }
  }
}
    ${PercentageAmountFragmentDoc}`;
