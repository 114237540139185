/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { JobFragmentDoc } from '../../../../shared/api/graphql/fragment/Job.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type UpdateCustomJobMutationVariables = Types.Exact<{
  input: Types.UpdateJobInput;
}>;

export type UpdateCustomJobMutation = {
  __typename?: 'Mutation';
  updateJob?: {
    __typename?: 'UpdateJobPayload';
    job?: {
      __typename?: 'Job';
      id: string;
      name: Types.Scalars['String']['input'];
      isFree: Types.Scalars['Boolean']['input'];
      partTypesFilteredOut: Types.Scalars['Boolean']['input'];
      priority?: number | null;
      type: Types.JobType;
      partTypes: Array<{
        __typename?: 'JobPartType';
        label?: Types.Scalars['String']['input'] | null;
        required: Types.Scalars['Boolean']['input'];
        partType: {
          __typename?: 'PartType';
          id: string;
          name: Types.Scalars['String']['input'];
          application: Types.PartTypeApplication;
        };
        attributes: Array<{
          __typename?: 'PartTypeAttribute';
          name: Types.Scalars['String']['input'];
          priority: number;
          values?: Array<Types.Scalars['String']['input']> | null;
          required: Types.Scalars['Boolean']['input'];
        }>;
      }>;
    } | null;
  } | null;
};

export const UpdateCustomJobDocument = `
    mutation UpdateCustomJob($input: UpdateJobInput!) {
  updateJob(input: $input) {
    job {
      ...Job
    }
  }
}
    ${JobFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateCustomJob: build.mutation<UpdateCustomJobMutation, UpdateCustomJobMutationVariables>({
      query: (variables) => ({ document: UpdateCustomJobDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateCustomJobMutation } = injectedRtkApi;
