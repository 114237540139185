import { type Vehicle } from 'entities/vehicle';
import { SearchSequenceType } from 'types/search';
import type { PartType } from './PartType';
import type { SearchEntryPointInterface } from 'features/searchForm';
import type { SearchSequenceCreatorInterface } from 'features/searchSequence';
import type { SearchSequence } from 'types/search';

export enum JobType {
  Custom = 'CUSTOM',
  Popular = 'POPULAR',
  VehicleSpecific = 'VEHICLE_SPECIFIC',
}

type JobPartType = {
  label?: string | null;
  attributes: NonNullable<PartType['attributes']>;
  partType: PartType;
  optional?: boolean;
};

type JobData = {
  id: string;
  name: string;
  isFree: boolean;
  partTypes: JobPartType[];
  hasFilteredPartTypes?: boolean;
  type?: JobType;
  priority?: number | null;
};

export class Job implements SearchEntryPointInterface, SearchSequenceCreatorInterface {
  id: string;

  name: string;

  isFree: boolean;

  partTypes: JobPartType[];

  hasFilteredPartTypes: boolean = false;

  type: JobType;

  priority: number | null;

  constructor(job: JobData) {
    this.id = job.id;
    this.name = job.name;
    this.isFree = job.isFree;
    this.partTypes = job.partTypes;
    this.hasFilteredPartTypes = job.hasFilteredPartTypes ?? false;
    this.type = job.type || JobType.Popular;
    this.priority = job.priority || null;
  }

  get isPaid() {
    return !this.isFree;
  }

  toString() {
    return this.name;
  }

  isMatchedToString(search: string): boolean {
    const searchValue = search.toLowerCase();

    const hasMatchInPartTypes = this.partTypes.some((jobPartType) =>
      jobPartType.partType.name.toLowerCase().includes(searchValue)
    );
    const hasMatchByName = this.name.toLowerCase().includes(searchValue);

    return hasMatchInPartTypes || hasMatchByName;
  }

  isVehicleRequired(): boolean {
    return true;
  }

  getClassName(): string {
    return 'fs-job';
  }

  getColumnTitle(): string {
    return 'jobs';
  }

  populateInput() {
    return this.partTypes?.[0]?.partType.name ?? '';
  }

  createSearchSequence(vehicle: Vehicle | null): SearchSequence {
    return {
      tasks: this.partTypes.map((jobPartType) => ({
        searchParams: {
          vehicle: vehicle?.id,
          vin: vehicle?.vin ?? undefined,
          state: vehicle?.state ?? undefined,
          plate: vehicle?.plate ?? undefined,
          part_text: jobPartType.partType.name,
          part_text_id: jobPartType.partType.id,
          attributes: jobPartType.attributes.reduce<Record<string, string[]>>(
            (result, item) => ({ ...result, [item.name]: item.values ?? [] }),
            {}
          ),
          job_id: this.id,
        },
        title:
          jobPartType.partType.name &&
          (jobPartType.label ? `${jobPartType.partType.name} (${jobPartType.label})` : jobPartType.partType.name),
        isCompleted: false,
        optional: jobPartType.optional,
      })),
      title: this.name,
      id: this.id,
      incompatiblePartsHidden: this.hasFilteredPartTypes ?? false,
      type: this.type === JobType.Custom ? SearchSequenceType.CustomJob : SearchSequenceType.Job,
    };
  }
}
