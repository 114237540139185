import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { parseQuery } from 'shared/lib/query';
import { generateUrl } from 'utils/url';
import type { IParseOptions } from 'qs';
import type { Location } from 'react-router-dom';
import type { UserAccountStatus } from 'shared/api';

/**
 * Type definition for location state that can be passed between routes
 */
export type LocationState = Partial<{
  background: AppLocation | null;
  from: string;
  signUpAccountStatus: UserAccountStatus;
  prevSearch: string;
}> | null;

/**
 * Type alias for Location with LocationState
 */
export type AppLocationBase = Location<LocationState>;

/**
 * Enhanced location class that extends the React Router Location
 * with additional functionality and typed state management.
 * Supports nested location objects and provides serialization methods.
 */
export class AppLocation implements AppLocationBase {
  pathname: string;

  search: string;

  hash: string;

  key: string;

  state: LocationState = null;

  /**
   * Creates a new AppLocation instance from a router Location object
   * @param location - The original Location object to enhance
   */
  constructor(location: AppLocationBase) {
    this.pathname = location.pathname;
    this.search = location.search;
    this.hash = location.hash;
    this.key = location.key;

    if (location.state) {
      this.state = {
        ...location.state,
        background: location.state.background ? new AppLocation(location.state.background) : null,
      };
    }
  }

  /**
   * Parses the search string into an object with typed query parameters
   * @template T - The expected type of the query parameters
   * @param options - Optional configuration for parsing query parameters
   * @returns An object containing the parsed query parameters
   */
  getSearchParams<T extends Record<string, unknown>>(options?: IParseOptions): T {
    return parseQuery<T>(this.search, options);
  }

  /**
   * Converts the location to a URL string
   * @returns The location as a formatted URL string
   */
  toString(): string {
    return generateUrl(this.pathname, undefined, this.search, this.hash);
  }

  /**
   * Creates a serializable representation of this location
   * @returns Plain object representation of the location
   */
  toJSON(): AppLocationBase {
    return {
      pathname: this.pathname,
      search: this.search,
      hash: this.hash,
      key: this.key,
      state: this.state,
    };
  }
}

/**
 * Custom hook that returns an enhanced AppLocation object
 * instead of the default React Router location
 * @returns An AppLocation instance with proper type definitions and additional functionality
 */
export const useAppLocation = () => {
  const location = useLocation();

  return useMemo(() => new AppLocation(location), [location]);
};
