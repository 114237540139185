import { currency } from 'shared/lib/string';

type PricePackageLineType = 'Addon' | 'Discount' | 'Disposal' | 'Fee' | 'Labor';

type CalculatedBy = 'TIRES' | 'LABOR' | 'DISPOSAL' | 'ADDON' | 'FEE';

type Option = { value: CalculatedBy; text: string };

const dictionary: Record<PricePackageLineType, string> = {
  Addon: 'Add-on',
  Discount: 'Discount',
  Disposal: 'Disposal fee',
  Fee: 'Fee',
  Labor: 'Labor',
};

export type PricePackageLineData = {
  type: PricePackageLineType;
  name?: string;
  amount?: number;
  description?: string | null;
  createdAt?: string | null;
  isPerTire?: boolean;
  isApplyTax?: boolean;
  isPercentage?: boolean;
  isPerQuantity?: boolean;
  max?: number | null;
  min?: number | null;
  calculatedBy?: CalculatedBy[];
};

export class PricePackageLine {
  type: PricePackageLineType;

  name: string;

  amount: number;

  description: string;

  createdAt: string | null;

  isPerTire: boolean;

  isApplyTax: boolean;

  isPercentage: boolean;

  isPerQuantity: boolean;

  max: number | null;

  min: number | null;

  calculatedBy: CalculatedBy[];

  constructor(data: PricePackageLineData) {
    this.type = data.type;
    this.name = data.name ?? '';
    this.amount = data.amount ?? 0;
    this.description = data.description ?? '';
    this.createdAt = data.createdAt ?? null;
    this.isPerTire = data.isPerTire ?? false;
    this.isApplyTax = data.isApplyTax ?? false;
    this.isPercentage = data.isPercentage ?? false;
    this.isPerQuantity = data.isPerQuantity ?? false;
    this.max = data.max ?? null;
    this.min = data.min ?? null;
    this.calculatedBy = data.calculatedBy ?? [];
  }

  get typeLabel() {
    return dictionary[this.type];
  }

  get amountLabel() {
    return this.isPercentage ? `${this.amount}%` : currency(this.amount);
  }

  get canApplyTax() {
    return this.type !== 'Discount';
  }

  get canHavePercentage() {
    return this.type !== 'Labor';
  }

  getCalculatedByOptions() {
    const tire: Option = { value: 'TIRES', text: 'TIRE RETAIL PRICE' };
    const labor: Option = { value: 'LABOR', text: 'LABOR' };
    const disposal: Option = { value: 'DISPOSAL', text: 'DISPOSAL' };
    const addon: Option = { value: 'ADDON', text: 'ADD-ONS' };
    const fee: Option = { value: 'FEE', text: 'FEES' };

    if (this.type === 'Addon') {
      return [tire, labor];
    }

    if (this.type === 'Fee') {
      return [tire, labor, addon];
    }

    if (this.type === 'Discount') {
      return [tire, labor, disposal, addon, fee];
    }

    return [];
  }
}
