import { useMutationCallback } from 'shared/api';
import { api as packagesApi } from '../getPricePackages/GetPricePackages.generated';
import { api as generatedApi } from './CreatePricePackage.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    CreatePricePackage: {
      invalidatesTags: ['PricePackage'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        const newPackage = data?.createPackage?.package;

        if (!newPackage) {
          return;
        }

        dispatch(
          packagesApi.util.updateQueryData('GetPricePackages', undefined, (draft) => {
            draft.currentUser?.activeMember?.shop?.retailPricing?.packages?.push(newPackage);
          })
        );
      },
    },
  },
});

const { useCreatePricePackageMutation } = api;

export const useCreatePricePackage = () => useMutationCallback(useCreatePricePackageMutation);
