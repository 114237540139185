/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { PricePackageFragmentDoc } from '../pricePackage/PricePackage.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type CreatePricePackageMutationVariables = Types.Exact<{
  input: Types.CreatePackageInput;
}>;

export type CreatePricePackageMutation = {
  __typename?: 'Mutation';
  createPackage?: {
    __typename?: 'CreatePackagePayload';
    package?: {
      __typename?: 'Package';
      id: string;
      name: Types.Scalars['String']['input'];
      isDefault: Types.Scalars['Boolean']['input'];
      items: Array<
        | {
            __typename?: 'Addon';
            name: Types.Scalars['String']['input'];
            createdAt: Types.Scalars['DateTime']['input'];
            price: Types.Scalars['BigDecimal']['input'];
            description?: Types.Scalars['String']['input'] | null;
            isPerTire: Types.Scalars['Boolean']['input'];
            isApplyTax: Types.Scalars['Boolean']['input'];
            isPercentage: Types.Scalars['Boolean']['input'];
            addonCalculatedBy: Array<Types.AddonCalculatedBy>;
            type: 'Addon';
            percentageAmount?: {
              __typename?: 'PercentageAmount';
              max?: Types.Scalars['BigDecimal']['input'] | null;
              min?: Types.Scalars['BigDecimal']['input'] | null;
              isPerQuantity: Types.Scalars['Boolean']['input'];
            } | null;
          }
        | {
            __typename?: 'Discount';
            name: Types.Scalars['String']['input'];
            createdAt: Types.Scalars['DateTime']['input'];
            price: Types.Scalars['BigDecimal']['input'];
            description?: Types.Scalars['String']['input'] | null;
            isPerTire: Types.Scalars['Boolean']['input'];
            isPercentage: Types.Scalars['Boolean']['input'];
            discountCalculatedBy: Array<Types.DiscountCalculatedBy>;
            type: 'Discount';
            percentageAmount?: {
              __typename?: 'PercentageAmount';
              max?: Types.Scalars['BigDecimal']['input'] | null;
              min?: Types.Scalars['BigDecimal']['input'] | null;
              isPerQuantity: Types.Scalars['Boolean']['input'];
            } | null;
          }
        | {
            __typename?: 'Disposal';
            name: Types.Scalars['String']['input'];
            createdAt: Types.Scalars['DateTime']['input'];
            price: Types.Scalars['BigDecimal']['input'];
            description?: Types.Scalars['String']['input'] | null;
            isApplyTax: Types.Scalars['Boolean']['input'];
            isPercentage: Types.Scalars['Boolean']['input'];
            type: 'Disposal';
            percentageAmount?: {
              __typename?: 'PercentageAmount';
              max?: Types.Scalars['BigDecimal']['input'] | null;
              min?: Types.Scalars['BigDecimal']['input'] | null;
              isPerQuantity: Types.Scalars['Boolean']['input'];
            } | null;
          }
        | {
            __typename?: 'Fee';
            name: Types.Scalars['String']['input'];
            createdAt: Types.Scalars['DateTime']['input'];
            price: Types.Scalars['BigDecimal']['input'];
            description?: Types.Scalars['String']['input'] | null;
            isPerTire: Types.Scalars['Boolean']['input'];
            isApplyTax: Types.Scalars['Boolean']['input'];
            feeCalculatedBy: Array<Types.FeeCalculatedBy>;
            isPercentage: Types.Scalars['Boolean']['input'];
            type: 'Fee';
            percentageAmount?: {
              __typename?: 'PercentageAmount';
              max?: Types.Scalars['BigDecimal']['input'] | null;
              min?: Types.Scalars['BigDecimal']['input'] | null;
              isPerQuantity: Types.Scalars['Boolean']['input'];
            } | null;
          }
        | {
            __typename?: 'Labor';
            name: Types.Scalars['String']['input'];
            createdAt: Types.Scalars['DateTime']['input'];
            price: Types.Scalars['BigDecimal']['input'];
            description?: Types.Scalars['String']['input'] | null;
            isPerTire: Types.Scalars['Boolean']['input'];
            isApplyTax: Types.Scalars['Boolean']['input'];
            type: 'Labor';
          }
      >;
    } | null;
  } | null;
};

export const CreatePricePackageDocument = `
    mutation CreatePricePackage($input: CreatePackageInput!) {
  createPackage(input: $input) {
    package {
      ...PricePackage
    }
  }
}
    ${PricePackageFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreatePricePackage: build.mutation<CreatePricePackageMutation, CreatePricePackageMutationVariables>({
      query: (variables) => ({ document: CreatePricePackageDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCreatePricePackageMutation } = injectedRtkApi;
