/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { DiagramFragmentDoc } from '../../../../shared/api/graphql/fragment/DiagramFragment.generated';
import { JobFragmentDoc } from '../../../../shared/api/graphql/fragment/Job.generated';
import { VehicleFragmentDoc } from '../../../../shared/api/graphql/fragment/VehicleFragment.generated';
import { TiresFragmentDoc } from '../TiresFragment.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type GetVehiclesByPlateVinQueryVariables = Types.Exact<{
  vin?: Types.InputMaybe<Types.Scalars['String']['input']>;
  plate?: Types.InputMaybe<Types.LicensePlate>;
}>;

export type GetVehiclesByPlateVinQuery = {
  __typename?: 'Query';
  vehicles?: Array<{
    __typename?: 'Vehicle';
    vin?: Types.Scalars['String']['input'] | null;
    id: string;
    type: Types.VehicleType;
    regions?: Array<Types.VehicleRegion> | null;
    year: number;
    diagrams?: Array<{
      __typename?: 'Diagram';
      id: string;
      url?: Types.Scalars['String']['input'] | null;
      name: Types.Scalars['String']['input'];
      brand?: { __typename?: 'Brand'; id: string; name: Types.Scalars['String']['input'] } | null;
      category?: { __typename?: 'PartCategory'; id: string; name: Types.Scalars['String']['input'] } | null;
    }> | null;
    jobs?: Array<{
      __typename?: 'Job';
      id: string;
      name: Types.Scalars['String']['input'];
      isFree: Types.Scalars['Boolean']['input'];
      partTypesFilteredOut: Types.Scalars['Boolean']['input'];
      priority?: number | null;
      type: Types.JobType;
      partTypes: Array<{
        __typename?: 'JobPartType';
        label?: Types.Scalars['String']['input'] | null;
        required: Types.Scalars['Boolean']['input'];
        partType: {
          __typename?: 'PartType';
          id: string;
          name: Types.Scalars['String']['input'];
          application: Types.PartTypeApplication;
        };
        attributes: Array<{
          __typename?: 'PartTypeAttribute';
          name: Types.Scalars['String']['input'];
          priority: number;
          values?: Array<Types.Scalars['String']['input']> | null;
          required: Types.Scalars['Boolean']['input'];
        }>;
      }>;
    }> | null;
    engine: {
      __typename?: 'VehicleEngine';
      name: Types.Scalars['String']['input'];
      fuel?: Types.Scalars['String']['input'] | null;
      designation?: Types.Scalars['String']['input'] | null;
      aspiration?: Types.Scalars['String']['input'] | null;
      id: string;
    };
    body?: {
      __typename?: 'VehicleBody';
      name?: Types.Scalars['String']['input'] | null;
      doors?: number | null;
      drive?: Types.Scalars['String']['input'] | null;
    } | null;
    make: { __typename?: 'VehicleMake'; id: string; name: Types.Scalars['String']['input']; priority: number };
    model: { __typename?: 'VehicleModel'; id: string; name: Types.Scalars['String']['input'] };
    subModel: { __typename?: 'VehicleSubModel'; id: string; name: Types.Scalars['String']['input'] };
    tires?: Array<{
      __typename?: 'VehicleTire';
      id: string;
      name: Types.Scalars['String']['input'];
      size: {
        __typename?: 'TireSize';
        width: Types.Scalars['Float']['input'];
        height: Types.Scalars['Float']['input'];
        rim: Types.Scalars['Float']['input'];
        speedRating?: Types.Scalars['String']['input'] | null;
        loadIndex?: number | null;
      };
      rearSize?: {
        __typename?: 'TireSize';
        width: Types.Scalars['Float']['input'];
        height: Types.Scalars['Float']['input'];
        rim: Types.Scalars['Float']['input'];
        speedRating?: Types.Scalars['String']['input'] | null;
        loadIndex?: number | null;
      } | null;
    }> | null;
  }> | null;
};

export const GetVehiclesByPlateVinDocument = `
    query GetVehiclesByPlateVin($vin: String, $plate: LicensePlate) {
  vehicles(vin: $vin, plate: $plate) {
    ...Vehicle
    ...Tires
    diagrams {
      ...Diagram
    }
    jobs {
      ...Job
    }
    vin
    engine {
      name
      fuel
      designation
      aspiration
      id
    }
    body {
      name
      doors
      drive
    }
  }
}
    ${VehicleFragmentDoc}
${TiresFragmentDoc}
${DiagramFragmentDoc}
${JobFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetVehiclesByPlateVin: build.query<GetVehiclesByPlateVinQuery, GetVehiclesByPlateVinQueryVariables | void>({
      query: (variables) => ({ document: GetVehiclesByPlateVinDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetVehiclesByPlateVinQuery, useLazyGetVehiclesByPlateVinQuery } = injectedRtkApi;
