import { sortBy, uniqBy } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { createPricePackageFromQuery } from '../../lib/createPricePackageFromQuery';
import { api } from './GetPricePackages.generated';
import type { PricePackage } from '../../model/PricePackage';

const emptyPricePackages: PricePackage[] = [];

const { useGetPricePackagesQuery } = api.enhanceEndpoints({
  endpoints: {
    GetPricePackages: {
      providesTags: ['PricePackage'],
    },
  },
});

export const useGetPricePackages = () => {
  const { data, ...rest } = useGetPricePackagesQuery();

  const pricePackages = useMemo(
    () =>
      data?.currentUser?.activeMember?.shop?.retailPricing?.packages?.map(createPricePackageFromQuery) ??
      emptyPricePackages,
    [data]
  );

  const recentLines = useMemo(() => {
    const uniqlines = uniqBy(
      pricePackages.flatMap((pricePackage) => pricePackage.items),
      'name'
    );

    return sortBy(uniqlines, 'createdAt').reverse();
  }, [pricePackages]);

  return {
    pricePackages,
    recentLines,
    ...rest,
  };
};
