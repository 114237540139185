import { Box, Button } from '@partstech/ui';
import { useCallback } from 'react';
import { AppLocation } from 'app/AppRouter/useAppLocation';
import { useAppLocationsHistory } from 'app/AppRouter/useAppLocationsHistory';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import type { BoxProps, IconProps } from '@partstech/ui';
import type { To } from 'react-router-dom';

type Props = {
  title: string;
  icon?: IconProps['name'];
  to?: To | number | null;
} & BoxProps;

export const GoBackButton = ({ title, to, icon = 'arrow_back', ...boxProps }: Props) => {
  const navigate = useAppNavigate();

  const { previousLocation, getLocationDistance } = useAppLocationsHistory();

  const handleArrowBackClick = useCallback(() => {
    if (!to) {
      navigate(getLocationDistance(previousLocation) ?? -1);

      return;
    }

    navigate(to instanceof AppLocation ? (getLocationDistance(to) ?? to) : to);
  }, [getLocationDistance, navigate, previousLocation, to]);

  return (
    <Box {...boxProps}>
      <Button variant="text" leadingIcon={icon} onClick={handleArrowBackClick} data-testid="goBackButton">
        {title}
      </Button>
    </Box>
  );
};
