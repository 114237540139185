import { DateTime } from 'luxon';
import { stringify } from 'qs';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { ReportGrouping } from 'shared/api';
import { parseQuery } from 'shared/lib/query';
import type { DateRangeOption, TemplatedOption } from '@partstech/ui';
import type { ReportsQueryParams } from 'utils/url';

export type RangeName = 'today' | 'lastWeek' | 'thisMonth' | 'lastMonth' | 'thisYear' | 'lastYear';
export type ChartType = 'main' | 'overTime' | 'perUser';

export const options: TemplatedOption<RangeName>[] = [
  { title: 'Today', rangeName: 'today' },
  { title: 'Last 7 days', rangeName: 'lastWeek' },
  { title: 'This month', rangeName: 'thisMonth' },
  { title: 'Previous month', rangeName: 'lastMonth' },
  { title: 'This year', rangeName: 'thisYear' },
  { title: 'Last year', rangeName: 'lastYear' },
];

export type TimeRange = DateRangeOption<RangeName>;

type Filters = {
  timeRange: TimeRange;
  locations: string[];
  chartType: ChartType;
};

export const useReportsFilters = () => {
  const { search } = useAppLocation();
  const [_, setSearchParams] = useSearchParams();

  const {
    rangeName: timeRangeQuery = 'thisMonth',
    locations: locationsQuery,
    startDate,
    endDate,
    chartType = 'main',
  } = useMemo(() => parseQuery<ReportsQueryParams>(search), [search]);

  const locations = useMemo(() => (locationsQuery ? locationsQuery.split(',') : []), [locationsQuery]);

  const timeRange: TimeRange = useMemo(() => {
    if (startDate && endDate) {
      return {
        title: 'Custom',
        range: {
          start: DateTime.fromISO(startDate).toJSDate(),
          end: DateTime.fromISO(`${endDate}T23:59:59.999`).toJSDate(),
        },
      };
    }
    const templateOption = options.find((o) => o.rangeName === timeRangeQuery);
    return templateOption || { title: 'Custom', range: {} };
  }, [timeRangeQuery, startDate, endDate]);

  const grouping: ReportGrouping = useMemo(() => {
    if ('rangeName' in timeRange && ['thisYear', 'lastYear'].includes(timeRange.rangeName)) {
      return ReportGrouping.Month;
    }

    return ReportGrouping.Day;
  }, [timeRange]);

  const applyFilters = useCallback(
    (filters: Filters) => {
      setSearchParams(
        stringify({
          rangeName:
            'rangeName' in filters.timeRange && filters.timeRange.rangeName !== 'thisMonth'
              ? filters.timeRange.rangeName
              : undefined,
          locations: filters.locations.length ? filters.locations.join(',') : undefined,
          chartType: filters.chartType !== 'main' ? filters.chartType : undefined,
          startDate:
            'range' in filters.timeRange && filters.timeRange.range.start
              ? DateTime.fromJSDate(filters.timeRange.range.start).toISODate()
              : undefined,
          endDate:
            'range' in filters.timeRange && filters.timeRange.range.end
              ? DateTime.fromJSDate(filters.timeRange.range.end).toISODate()
              : undefined,
        })
      );
    },
    [setSearchParams]
  );

  return {
    timeRange,
    locations,
    chartType,
    grouping,
    applyFilters,
  };
};
