import { Box, Button, TextField, useMedia } from '@partstech/ui';
import { useToggle } from '@partstech/ui/hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchResults } from '../../../SearchResultsContext';
import { CollapsibleFilterWrapper } from './CollapsibleFilterWrapper';
import { DefaultFilterWrapper } from './DefaultFilterWrapper';
import { Options as DefaultOptions } from './Options';
import { ShowAll } from './ShowAll';
import type { FilterProps } from './types';
import type { FilterOption } from 'types/search';

const getCheckedOptions = (checkedFilterOptions: string[], filterOptions: FilterOption[]): string[] => {
  const selectedRadioOption =
    filterOptions.find((option) => option.type === 'radio' && checkedFilterOptions.includes(option.value))?.value ??
    filterOptions.find((option) => option.type === 'radio')?.value;

  const selectedCheckboxOptions = checkedFilterOptions.filter((value) =>
    filterOptions.some((option) => (!option.type || option.type === 'checkbox') && option.value === value)
  );

  return selectedRadioOption ? [selectedRadioOption, ...selectedCheckboxOptions] : selectedCheckboxOptions;
};

const VISIBLE_LIMIT = 5;
const SEARCHABLE_LIMIT = 15;

const getVisibleLimit = (isFiltersImprovementEnabled: boolean) => (isFiltersImprovementEnabled ? 10 : VISIBLE_LIMIT);
const getSearchableLimit = (isFiltersImprovementEnabled: boolean) =>
  isFiltersImprovementEnabled ? 10 : SEARCHABLE_LIMIT;

const emptyCheckedOptions: string[] = [];

export const DefaultFilter: React.FunctionComponent<FilterProps> = (props) => {
  const {
    isHidden = false,
    name,
    options: initialOptions,
    OptionsComponent: Options = DefaultOptions,
    onChange,
  } = props;

  const { isMobile } = useMedia();

  const { checkedFilters, isFiltersImprovementEnabled } = useSearchResults();

  const checkedOptions = useMemo(() => checkedFilters[name] ?? emptyCheckedOptions, [checkedFilters, name]);

  const [searchString, setSearchString] = useState('');

  const { isToggle: isOpen, setToggle: setIsOpen } = useToggle();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchString(e.target.value);
      setIsOpen(checkedOptions.length > 0 || e.target.value.length > 0);
    },
    [checkedOptions.length, setIsOpen]
  );

  const handleSeeAll = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      setIsOpen(true);
    },
    [setIsOpen]
  );

  const handleClear = useCallback(() => {
    setSearchString('');
    setIsOpen(checkedOptions.length > 0);
  }, [checkedOptions.length, setIsOpen]);

  useEffect(() => {
    if (!isMobile) {
      setSearchString('');
    }
  }, [isMobile]);

  useEffect(() => {
    if (checkedOptions.length > 0) {
      setIsOpen(true);
    }
  }, [checkedOptions.length, setIsOpen]);

  const options = useMemo(
    () =>
      isOpen
        ? initialOptions.filter((option) => String(option.name).toLowerCase().includes(searchString.toLowerCase()))
        : initialOptions.slice(0, getVisibleLimit(isFiltersImprovementEnabled)),
    [initialOptions, isFiltersImprovementEnabled, isOpen, searchString]
  );

  const filteredCheckedOptions = useMemo(() => getCheckedOptions(checkedOptions, options), [checkedOptions, options]);

  const needsSearchInput = initialOptions.length > getSearchableLimit(isFiltersImprovementEnabled);
  const needsToCut = initialOptions.length > getVisibleLimit(isFiltersImprovementEnabled);

  const FilterWrapper = isFiltersImprovementEnabled ? DefaultFilterWrapper : CollapsibleFilterWrapper;

  return (
    <div style={{ display: isHidden ? 'none' : 'block' }}>
      <FilterWrapper label={props.label} name={props.name} isCollapsed={props.isCollapsed} mb={4}>
        {needsSearchInput && (
          <Box position="relative" pb={2}>
            <TextField
              maxLength={100}
              placeholder="Search"
              value={searchString}
              onChange={handleChange}
              leadingIcon="search"
              suffix={
                searchString ? (
                  <Button onClick={handleClear} trailingIcon="close" iconColor="mono40" variant="light" />
                ) : undefined
              }
            />
          </Box>
        )}

        <div data-testid="options">
          <Options
            name={name}
            options={options}
            onChange={onChange}
            checkedOptions={filteredCheckedOptions}
            isFiltersImprovementEnabled={isFiltersImprovementEnabled}
          />
        </div>

        {!searchString && needsToCut && checkedOptions.length === 0 && !isOpen && (
          <ShowAll isFiltersImprovementEnabled={isFiltersImprovementEnabled} onClick={handleSeeAll} mt={1} />
        )}
      </FilterWrapper>
    </div>
  );
};
