import { useMutationCallback } from 'shared/api';
import { api as packagesApi } from '../getPricePackages/GetPricePackages.generated';
import { api as generatedApi } from './RemovePricePackage.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    RemovePricePackage: {
      invalidatesTags: ['PricePackage'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        const deletePackageId = data?.deletePackage?.id;

        if (!deletePackageId) {
          return;
        }

        dispatch(
          packagesApi.util.updateQueryData('GetPricePackages', undefined, (draft) => {
            const retailPricing = draft.currentUser?.activeMember?.shop?.retailPricing;

            if (retailPricing) {
              Object.assign(retailPricing, {
                packages: retailPricing.packages?.filter((pricePackage) => pricePackage.id !== deletePackageId),
              });
            }
          })
        );
      },
    },
  },
});

export const { useRemovePricePackageMutation } = api;

export const useRemovePricePackage = () => useMutationCallback(useRemovePricePackageMutation);
