import { useMutationCallback } from 'shared/api';
import { api as generatedApi } from './UpdatePricePackage.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    UpdatePricePackage: {
      invalidatesTags: ['PricePackage'],
    },
  },
});

export const { useUpdatePricePackageMutation } = api;

export const useUpdatePricePackage = () => useMutationCallback(useUpdatePricePackageMutation);
