import { useCreateModal } from '@partstech/ui/hooks';
import { useCallback, useEffect } from 'react';
import { useAppLocationsHistory } from 'app/AppRouter/useAppLocationsHistory';
import { useAppNavigate } from 'app/AppRouter/useAppNavigate';
import { ProfileModal } from 'components/Modal/ProfileModal';
import { HashRoutes } from 'constant';

export const useProfileModal = () => {
  const navigate = useAppNavigate();
  const {
    currentLocation: { hash },
    previousLocation,
    getLocationDistance,
  } = useAppLocationsHistory();

  const handleClose = useCallback(
    () => navigate(getLocationDistance(previousLocation) ?? -1),
    [getLocationDistance, navigate, previousLocation]
  );

  const { open, close } = useCreateModal(
    ProfileModal,
    { size: 'dialog', onClose: handleClose },
    { customId: 'profile' }
  );

  useEffect(() => {
    if (hash !== HashRoutes.PROFILE) {
      close();
      return;
    }

    open();
  }, [open, close, hash]);
};
