import { Box, Typography, useMedia } from '@partstech/ui';
import { Outlet } from 'app/AppRouter/Outlet';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { useLayoutContext } from 'app/contexts/LayoutProvider/LayoutContext';
import { GoBackButton } from 'components/GoBackButton';
import { Routes } from 'constant';
import { MyShopTabs } from './MyShopTabs';
import type { PropsWithChildren } from 'react';

export const MyShopLayout = ({ children }: PropsWithChildren) => {
  const { isMobile } = useMedia();

  const location = useAppLocation();

  const { headerHeight } = useLayoutContext();

  const isBackToSearchResultsAvailable = Boolean(location.state?.from === Routes.SEARCH_RESULTS);

  return (
    <Box
      background="white"
      minHeight={`calc(100vh - ${headerHeight}px)`}
      height="100%"
      px={{ sm: 0, md: 4 }}
      data-testid="myShopContainer"
    >
      {isBackToSearchResultsAvailable && <GoBackButton title="Back to search results" pt={{ md: 6 }} />}

      <Box py={{ md: 6 }} minHeight={`calc(100vh - ${headerHeight}px)`} height="100%">
        <Box maxWidth={{ md: 262 }} mx="auto">
          {!isMobile && <Typography variant="h2">Settings</Typography>}

          <Box mt={{ md: 6 }} mb={6}>
            <MyShopTabs />
          </Box>
        </Box>

        <Outlet>{children}</Outlet>
      </Box>
    </Box>
  );
};
