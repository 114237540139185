/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type RemovePricePackageMutationVariables = Types.Exact<{
  input: Types.DeletePackageInput;
}>;

export type RemovePricePackageMutation = {
  __typename?: 'Mutation';
  deletePackage?: { __typename?: 'DeletePackagePayload'; id: string } | null;
};

export const RemovePricePackageDocument = `
    mutation RemovePricePackage($input: DeletePackageInput!) {
  deletePackage(input: $input) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    RemovePricePackage: build.mutation<RemovePricePackageMutation, RemovePricePackageMutationVariables>({
      query: (variables) => ({ document: RemovePricePackageDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useRemovePricePackageMutation } = injectedRtkApi;
