import { memo } from 'react';
import { ScrollRestoration } from 'react-router-dom';
import { useAppNavigationHandler } from './useAppNavigationHandler';
import { useSaveAppLocationsHistory } from './useSaveAppLocationsHistory';
import type { PropsWithChildren } from 'react';

export const AppNavigationHandler = memo(({ children }: PropsWithChildren) => {
  const { hasNeedScrollRestoration } = useAppNavigationHandler();

  useSaveAppLocationsHistory();

  return (
    <>
      {children}

      {hasNeedScrollRestoration && <ScrollRestoration />}
    </>
  );
});
