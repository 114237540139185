import { api as generatedApi } from './ChangeCustomJobPriorities.generated';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    ChangeCustomJobPriorities: {
      invalidatesTags: ['CustomJob', 'VehicleById', 'VehicleByPlateVin'],
    },
  },
});

export const { useChangeCustomJobPrioritiesMutation } = api;
